import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['panel', 'tab']
  static values = {
    activeTab: { type: String, default: '0' }
  }

  connect () {
    this.setTabActive()
    this.setPanelVisibility()
  }

  showTab (event) {
    const tab = event.currentTarget
    this.activeTabValue = tab.getAttribute('index')
    this.setTabActive()
    this.setPanelVisibility()
  }

  setTabActive () {
    this.tabTargets.forEach(tab => {
      const isActive = tab.getAttribute('index') === this.activeTabValue
      tab.setAttribute('aria-selected', isActive)
      tab.classList.toggle('active', isActive)
    })
  }

  setPanelVisibility () {
    this.panelTargets.forEach(panel => {
      const isActive = panel.getAttribute('index') === this.activeTabValue
      panel.setAttribute('aria-hidden', !isActive)
      panel.classList.toggle('hidden', !isActive)
    })
  }
}
